import { Vue, Component } from "vue-property-decorator";

@Component
export class DeviceLogic extends Vue {
  get isMobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get isMobileSmAndDown() {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
