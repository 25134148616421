import { Component, Vue } from "vue-property-decorator";
import { IDataDialog } from "@components/global/info_dialog/InfoDialogController";
import { SnackbarProps } from "@components/global/snackbar/Snackbar";
import { Optional } from "@/controllers/abstracts/AbstractController";

@Component
export class DialogLogic extends Vue {
  openInfoDialog(props: Omit<IDataDialog, "open">) {
    this.$bus.$emit("openDialog", props);
  }

  showSnackbar(props: Optional<SnackbarProps, "position">) {
    this.$bus.$emit("showSnackbar", props);
  }

  closeInfoDialog() {
    this.$bus.$emit("closeDialog");
  }

  closeGlobalLoading() {
    this.$bus.$emit("closeGlobalLoading");
  }

  openGlobalLoading() {
    this.$bus.$emit("openGlobalLoading");
  }
}
